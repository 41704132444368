import React, { useEffect, useState } from "react";
import { RichText } from "@graphcms/rich-text-react-renderer";
import { RichTextContent } from "@graphcms/rich-text-types";
import chevron from "../../assets/img/icons/Chevron.svg";
import shortCrookedLine from "../../assets/img/short-crooked-line.svg";
import leftTopPattern from "../../assets/img/patterns/faqsLeftTopPattern.svg";
import rightTopTopPattern from "../../assets/img/patterns/faqsRightTopTopPattern.svg";
import rightTopBottomPattern from "../../assets/img/patterns/faqsRightTopBottomPattern.svg";
import { getFaqsData } from "../../services/HygraphService";
import "./Faqs.scss";

interface FaqItem {
  question: string;
  answer: { raw: RichTextContent };
}

interface FaqContent {
  faqTitle: string;
  faqDescription: string;
  faqItemTitle: string;
  faqItems: FaqItem[];
}

interface FaqsProps {
  pageTitle: string;
  setFaqDescription: (description: string) => void;
}

const Faqs: React.FC<FaqsProps> = ({ pageTitle, setFaqDescription }) => {
  const [faqContent, setFaqContent] = useState<FaqContent | null>(null);
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const data = await getFaqsData(pageTitle);
        setFaqContent({
          faqTitle: data.title,
          faqDescription: data.description,
          faqItemTitle: data.faqItemTitle,
          faqItems: data.faqItems.map(
            (item: { faqQuestion: string; faqAnswer: { raw: RichTextContent } }) => ({
              question: item.faqQuestion,
              answer: item.faqAnswer,
            })
          ),
        });
        setFaqDescription(data.description);
      } catch (error) {
        console.error(error);
      }
    };
    fetchFaqs();
  }, []);

  const toggleAccordion = (index: number) => {
    setActiveIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  return (
    <div className="faqs-content">
      {/* Header Section */}
      <div className="faqs-header">
        <div className="left-pattern">
          <img src={leftTopPattern} alt="Left Pattern" />
        </div>
        <div className="right-pattern">
          <img src={rightTopTopPattern} alt="Right Top Pattern" className="right-pattern-top" />
          <img
            src={rightTopBottomPattern}
            alt="Right Bottom Pattern"
            className="right-pattern-bottom"
          />
        </div>
        <div className="faqs-intro">
          <h1>{faqContent?.faqTitle}</h1>
          <img src={shortCrookedLine} alt="Short Crooked Line" className="short-crooked-line" />
          <p>{faqContent?.faqDescription}</p>
        </div>
      </div>

      {/* Accordion Section */}
      <div className="faq-items-container">
        <h2>{faqContent?.faqItemTitle}</h2>
        {faqContent?.faqItems.map((item, index) => (
          <div key={index} className={`faq-item ${activeIndices.includes(index) ? "active" : ""}`}>
            <button className="faq-question" onClick={() => toggleAccordion(index)}>
              {item.question}
              <img
                src={chevron}
                alt="Toggle"
                className={`chevron ${activeIndices.includes(index) ? "rotate" : ""}`}
              />
            </button>
            <div className="faq-answer">
              <RichText
                content={item.answer?.raw}
                renderers={{
                  p: ({ children }) => <p className="faq-rich-text-paragraph">{children}</p>,
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faqs;
